import * as React from "react"

import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ctl from "@netlify/classnames-template-literals"

const NotFoundPage = () => (
  <Layout title="404: Page Not Found" description="The page you are looking for does not exist" footerClassName='!mt-0'>
    <section className={wrapperStyle}>
      <div className='md:pt-[93px] md-max:text-center'>
        <h1 className='text-[30px] md:text-[40px] leading-[1.4] font-extrabold mb-3 md:mb-[23px]'>
          Careful now!
        </h1>
        <p className='text-[15px] mt-0 md:text-[20px] leading-[1.6] mb-[30px]'>
          The page you are looking for does not exist, <br/>
          go back to the homepage and we’ll help you <br/>
          get started trading.
        </p>
        <Link to='/' className='text-button-blue font-medium'>
          Homepage
        </Link>
      </div>
      <StaticImage
        src='../assets/images/pages/404/404.webp'
        alt='Woman on the edge of a cliff'
        className='max-w-[298px] md-max:ml-auto md:max-w-[650px]'
      />
    </section>
  </Layout>
)

const wrapperStyle = ctl(`
  flex md:justify-between gap-x-[80px] md:pl-[9%] pt-[55px] gap-y-[60px]
  md-max:flex-col
`)

export default NotFoundPage
